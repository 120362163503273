import * as React from 'react';
import { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import Seo from '../components/layout/Seo';
import FileUpload from '../components/form/FileUpload';
import { IconMail2, IconPhone, IconPlace } from '../components/icons/Icons';
import Map from '../components/common/Map';
import AdditionalForms from '../components/common/AdditionalForms';
import InputField from '../components/form/InputField';
import FormWrapper from '../components/form/FormWrapper'
import useFormState from "../components/form/hooks/useFormState";
import HowItWorks from "../components/common/HowItWorks";

const EZShipBox = () => {
  const CANONICAL_PATH_NAME = '/ez-ship-box-request-form/'
  if (typeof window !== `undefined`  && window && window.location.pathname !== CANONICAL_PATH_NAME) {
    window.history.replaceState(null, null, CANONICAL_PATH_NAME + (window.location?.search||""))
  }
  const [form, _setForm, updateForm] = useFormState({
    name: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    item_type: '',
    photos: '',
    zip: ''
  })
  const [newUserInfo, setNewUserInfo] = useState({ profileImages: [] });
  const updateUploadedFiles = files => setNewUserInfo({ ...newUserInfo, profileImages: files });
  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo
        title="EZ Ship Box Request Form | sellusyourjewelry.com"
        description="Get Instant Cash for Your Jewelry with our EZ Ship Box! Request your free appraisal kit now and turn your unwanted jewelry into money today."
        canonical={CANONICAL_PATH_NAME}
      />

      <section>
        <div className="flex flex-col items-center justify-center bg-gradient-to-br from-[#d1c3a4] to-[#b1a07f] lg:flex-row">
          <div className="flex w-full justify-center">
            <StaticImage
              src={'../images/ez-ship-box-form-header.png'}
              alt={'EZ Ship Box Supplies Provided'}
              loading={'eager'}
            />
          </div>
          <div className="w-full">
            <div className="p-10 text-center text-3xl font-black text-black md:text-5xl xl:text-6xl">
              THE SOLUTION <br /> to selling your jewelry
            </div>
            <div className="flex w-full flex-col justify-center p-10 align-middle xl:px-20 ">
              <FormWrapper
                className={'bg-white p-8'}
                name={'EZ Ship Box'}
                action={'/thank-you/'}
                form={form}
              >
                <h1 className="text-center text-3xl uppercase ">REQUEST AN EZ SHIP BOX TODAY!</h1>
                <p className="text-center">
                  Fill out this simple form to receive all necessary supplies to send us the items.
                  you want to sell.
                  Simply take it to post office. Send it <b>registered mail and insured*</b> and we will refund your
                  cost of shipping and insurance.
                  <em className={"block my-4"}>* Be sure to protect your valuables with adequate shipping insurance</em>
                </p>

                <div className="flex flex-col space-y-4">
                  <InputField
                    name={'name'}
                    label={'Name'}
                    form={form}
                    onChange={updateForm}
                    required={true}
                  />
                  <InputField
                    name={'phone'}
                    label={'Phone'}
                    form={form}
                    onChange={updateForm}
                    required={true}
                  />
                  <InputField
                    name={'email'}
                    label={'E-Mail'}
                    form={form}
                    onChange={updateForm}
                    required={true}
                  />
                  <InputField
                    name={'address1'}
                    label={'Street Address'}
                    form={form}
                    onChange={updateForm}
                    required={true}
                  />
                  <InputField
                    name={'address2'}
                    label={'Address Line 2'}
                    form={form}
                    onChange={updateForm}
                  />
                  <span className="grid grid-cols-4 gap-4">
                    <InputField
                      name={'city'}
                      label={'City'}
                      form={form}
                      onChange={updateForm}
                        required={true}
                      className={' col-span-3'}
                    />
                    <InputField
                      name={'state'}
                      label={'State'}
                      form={form}
                      onChange={updateForm}
                        required={true}
                      className={'col-span-1'}
                    />
                  </span>
                  <InputField
                    name={'item_type'}
                    label={'What are you looking to sell?'}
                    form={form}
                    onChange={updateForm}
                    required={true}
                  />
                  <br />
                  <FileUpload
                    id={'photos'}
                    label={'Add photos'}
                    name={'photos'}
                    accept={'.jpg,.png,.jpeg'}
                    updateFilesCb={updateUploadedFiles}
                    multiple
                  />
                  * Max. file size: 40 MB, Max. files: 5.
                  <br />
                  <br />
                  Please attach photos of items & appraisals/certificates. The red 'X's are normal.
                </div>
                <br />
                <br />
                <button className=" btn-cta w-[15rem] p-5">SUBMIT FORM</button>
              </FormWrapper>
            </div>
          </div>
        </div>
      </section>

      <HowItWorks />

      <AdditionalForms />

      <div className="max-2-max flex flex-col justify-center" />
      <div className="w-full py-10 shadow-lg" />
      <div className={'container mx-auto mt-16 flex flex-wrap gap-y-16 px-4 md:p-0'}>
        <section className={'w-full'}>
          <h2 className={'text-center text-4xl uppercase'}>Still have Questions?</h2>
        </section>
        <section className={'w-full'}>
          <p className={'mx-auto w-full text-center text-lg lg:w-1/2 lg:text-xl 2xl:w-2/5'}>
            Our highly trained specialists, coupled with our international sales reach, allow us to
            offer the very best prices for your jewelry.
          </p>
        </section>

        <section className={'flex w-full flex-wrap gap-16 lg:flex-nowrap'}>
          <div
            className={
              'align-center flex h-full w-full flex-col justify-center py-24 text-center lg:w-1/3'
            }
          >
            <div className={'mb-8 text-5xl text-accent'}>
              <IconPlace />
            </div>
            <div className={'text-3xl uppercase'}>Visit Us</div>
            <div className={'mt-16 text-link'}>
              9595 Harding Avenue, Bal Harbour Florida 33154
            </div>
          </div>
          <div
            className={
              'align-center flex w-full flex-col justify-center border-l border-r border-accent text-center lg:w-1/3'
            }
          >
            <div className={'mb-8 text-5xl text-accent'}>
              <IconPhone />
            </div>
            <div className={'text-3xl uppercase'}>Call Us</div>
            <div className={'mt-16 text-accent'}><a href={"tel:+13057706955"}>tel: 305 770 6955</a></div>
          </div>
          <div className={'align-center flex w-full flex-col justify-center text-center lg:w-1/3'}>
            <div className={'mb-8 text-5xl text-accent'}>
              <IconMail2 />
            </div>
            <div className={'text-3xl uppercase'}>Email Us</div>
            <div className={'mt-16 text-accent'}><a href={"mailto:sell@grayandsons.com"}>sell@grayandsons.com</a></div>
          </div>
        </section>
      </div>

      <section className={'mt-16'}>
        <Map />
      </section>
    </Layout>
  );
};

export default EZShipBox;
